<template>
    <div>
        <v-radio-group v-model="contaIndex" @change="setConta">
            <template v-for="(conta, index) of contas">
                <v-radio :key="index" :label="conta.nome" />
            </template>
        </v-radio-group>
        <div class="text-right">
            <v-btn class="mr-2" text @click="cancel">Cancelar</v-btn>
            <v-btn color="primary" text @click="ok">Aplicar</v-btn>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TipoContaForm',
        props: {
            value: null,
            opened: false,
        },
        watch: {
            opened(value) {
                console.log("opened", this.value)
                if (value) {
                    this.conta = this.value.data.id ? this.value.data : null;
                    this.contaIndex = this.contas.findIndex(conta => conta.id === this.conta.id)
                }
            },
        },
        data() {
            return {
                contas: [{id:1,nome:'Conta Corrente'},{id:2,nome:'Conta Poupança'},{id:3,nome:'Conta Investimento'}],
                conta: this.value.data.id ? this.value.data : null,
                contaIndex: 0,
            };
        },
        methods: {
            ok() {
                if(!this.conta){
                    this.conta = this.contas[0]
                }
                const response = this._.cloneDeep(this.value);
                response.data.id = this.contas[this.contaIndex].id;
                response.data.nome = this.contas[this.contaIndex].nome;
                this.$emit('input', response);
                this.close();
            },
            cancel() {
                this.$emit('canceled');
                this.close();
            },
            close() {
                this.$emit('update:opened', false);
            },
            setConta(contaIndex){
                console.log("setConta", contaIndex)
                this.conta = this.contas[contaIndex]
                //this.contaIndex = this.conta.id -1;
            }
        },
    };
</script>

<style scoped>

</style>