<template>
    <v-dialog v-model="dialog" persistent max-width="500px">
        <v-card v-if="dialog">
<!--            <v-card-title>
                <span class="headline">Vincular Projetos</span>
            </v-card-title>-->
            <v-card-title class="pa-0">
                <v-row no-gutters>
                    <v-col>
                        <div class="grey lighten-5 py-1 px-3" style="word-break: keep-all; white-space: nowrap">
                            <v-row>
                                <v-col class="pb-0 pt-0">
                                    <span class="d-block caption font-weight-medium">Lançamento</span>
                                    <span class="subtitle-2 font-weight-light">{{transacao.memo}}</span>
                                </v-col>
                                <v-col class="pb-0 pt-0 shrink">
                                    <span class="d-block caption font-weight-medium">Valor</span>
                                    <span class="subtitle-2 font-weight-light">{{transacao.trnamt | formatNumber}}</span>
                                </v-col>
                            </v-row>
                            <v-row>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="py-0">
                <v-container class="pa-0">

                    <v-row>
                        <v-col>
                            <v-text-field v-model="searchValue" label="Busca" hide-details clearable />
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-card>
                                <v-tabs v-model="tab" grow background-color="primary" dark>
                                    <v-tab>Projetos</v-tab>
                                    <v-tab>Retornos</v-tab>
                                </v-tabs>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div style="height: 200px; overflow-y: auto">
                                <v-tabs-items v-model="tab">
                                    <v-tab-item>
                                        <v-list dense>
                                            <v-list-item-group
                                                v-model="projetosIdsSelecionados"
                                                multiple>
                                                <template v-for="(projeto, i) in projetosFiltrados">
                                                    <v-list-item :key="i" :value="projeto.id"
                                                                 active-class="primary--text text--accent-4"
                                                    >
                                                        <template v-slot:default="{ active }">
                                                            <div>
                                                                <v-checkbox class="mt-0"
                                                                            :input-value="active"
                                                                            color="primary accent-4" hide-details
                                                                ></v-checkbox>
                                                            </div>
                                                            <v-list-item-content>
                                                                <v-list-item-title class="font-weight-regular" v-text="projeto.cliente"></v-list-item-title>
                                                            </v-list-item-content>

                                                            <v-list-item-action>
                                                                <v-list-item-action-text>
                                                                    {{projeto.valorTotal | formatNumber}}
                                                                </v-list-item-action-text>
                                                            </v-list-item-action>
                                                        </template>
                                                    </v-list-item>
                                                </template>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <v-list dense>
                                            <v-list-item-group
                                                v-model="retornoIdSelecionado">
                                                <template v-for="(retorno, i) in retornosFiltrados">
                                                    <v-list-item :key="i" :value="retorno.id"
                                                                 active-class="primary--text text--accent-4"
                                                    >
                                                        <template v-slot:default="{ active }">
                                                            <div>
                                                                <v-checkbox class="mt-0"
                                                                            :input-value="active"
                                                                            color="primary accent-4" hide-details
                                                                ></v-checkbox>
                                                            </div>
                                                            <v-list-item-content>
                                                                <v-list-item-title class="font-weight-regular" v-text="retorno.nomeArquivo"></v-list-item-title>
                                                                <v-list-item-subtitle class="font-weight-light">{{retorno.totalLiquidacoesNormais}} projetos</v-list-item-subtitle>
                                                            </v-list-item-content>

                                                            <v-list-item-action>
                                                                <v-list-item-action-text>
                                                                    {{retorno.valorTotalPago | formatNumber}}
                                                                </v-list-item-action-text>
                                                            </v-list-item-action>
                                                        </template>
                                                    </v-list-item>
                                                </template>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-tab-item>
                                </v-tabs-items>

                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>
                    <div class="float-right">
                        <v-btn color="primary" text depressed @click="save" >
                            Salvar
                        </v-btn>
                    </div>
                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "VincularProjetosDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            transacao: null,
            projetos: [],
            projetosIdsSelecionados: [],
            retornos: [],
            retornoIdSelecionado: null,
            searchValue: null,
            tab: 0,
        }
    },
    /*watch:{
        searchValue(value){
            console.log("searchValue", value)
        }
    },*/
    watch:{
        tab(value){
            this.searchValue = null;
        }
    },
    computed:{
        projetosFiltrados:{
            cache:false,
            get(){
                return this.projetos.filter(projeto => {
                    if(this.searchValue) {
                        if (projeto.cliente.indexOf(this.searchValue.toUpperCase()) > -1) {
                            return true;
                        }
                        return false;
                    }else{
                        return true;
                    }
                })

            }
        },
        retornosFiltrados:{
            cache:false,
            get(){
                return this.retornos.filter(retorno => {
                    if(this.searchValue) {
                        if (retorno.nomeArquivo.indexOf(this.searchValue.toUpperCase()) > -1) {
                            return true;
                        }
                        return false;
                    }else{
                        return true;
                    }
                })

            }
        },
    },
    methods: {
        openDialog(servicosIdsSelecionados, subItemType, transacao) {
            console.log(servicosIdsSelecionados, subItemType, transacao)
            this.dialog = true;
            this.transacao = transacao;
            if(subItemType === 0){
                this.projetosIdsSelecionados = this._.cloneDeep(servicosIdsSelecionados);
                this.retornoIdSelecionado = null;
            }
            if(subItemType === 1){
                this.retornoIdSelecionado = this._.cloneDeep(servicosIdsSelecionados)[0];
                this.projetosIdsSelecionados = []
            }
            this.tab = subItemType;
            this.getRecursos();
        },
        closeDialog() {
            this.dialog = false;
            this.searchValue = null;
        },
        getRecursos(){
            this.loading = true;
            this.$apollo.query({
                query : gql`query {
                    projetos:projetosSemTransacao{
                        id
                        cliente
                        valorTotal
                    }
                    retornos:retornosSemTransacao{
                        id
                        nomeArquivo
                        valorTotalPago
                        totalLiquidacoesNormais
                    }
                }`,
            }).then((result) => {
                this.projetos = result.data.projetos;
                this.retornos = result.data.retornos;
            }).finally(()=>{
                this.loading = false
            })
        },
        save(){
            this.closeDialog();
            let servicosIdsSelecionados = this.tab === 0 ? this.projetosIdsSelecionados : [this.retornoIdSelecionado]
            let descricao = null;
            if(servicosIdsSelecionados.length > 0){
                if(this.tab === 0){
                    descricao = "Referente a " + this.projetosIdsSelecionados.length + " projetos"
                }else{
                    let retorno = this.retornos.find(retorno => retorno.id === this.retornoIdSelecionado)
                    descricao = "Referente a " + retorno.totalLiquidacoesNormais + " projetos"
                }
            }
            console.log(servicosIdsSelecionados, this.tab, descricao)
            this.$emit("onServicosSelecionados", servicosIdsSelecionados, this.tab, descricao)
        }
    }
}
</script>

<style scoped>

</style>