<template>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Criar {{transacao.trnamt > 0 ? ' Receita' : ' Despesa'}}</span>
            </v-card-title>
            <v-card-text class="py-0">
                <v-container class="pa-0">
                    <v-form ref="form1" v-model="form1Valid" lazy-validation>
                        <v-row no-gutters>
                            <v-col>
                                <div class="grey lighten-5 py-1 px-3 mb-4 mt-1">
                                    <v-row>
                                        <v-col class="pb-0 pt-0">
                                            <span class="d-block caption font-weight-medium">Lançamento</span>
                                            <span>{{transacao.memo}}</span>
                                        </v-col>
                                        <v-col class="pb-0 pt-0 shrink">
                                            <span class="d-block caption font-weight-medium">Dcto.</span>
                                            <span>{{transacao.checknum}}</span>
                                        </v-col>
<!--                                        <v-col class="pb-0 pt-0 shrink" style="white-space: nowrap">
                                            <span class="d-block caption font-weight-medium">Valor</span>
                                            <span :class="transacao.trnamt > 0 ? 'indigo&#45;&#45;text' : 'red&#45;&#45;text'">{{transacao.trnamt | formatNumber}}</span>
                                        </v-col>
                                        <v-col class="pb-0 pt-0 shrink" style="white-space: nowrap">
                                            <span class="d-block caption font-weight-medium">Data</span>
                                            <span>{{transacao.dtposted | moment("DD/MM/YYYY HH:mm")}}</span>
                                        </v-col>-->
                                    </v-row>
                                    <v-row>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="6" class="py-0">
                                <v-autocomplete v-model="item" label="Item"
                                                placeholder=" " :items="itensFiltrados"
                                                item-text="nome" return-object clearable
                                                :rules="requiredRules" @change="onItemChange">
                                    <template v-slot:item="data">
                                        <template v-if="typeof data.item !== 'object'">
                                            <div class="caption">
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                                                <v-list-item-subtitle v-html="data.item.descricao"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <v-text-field v-model="descricao"
                                              :readonly="transacao.trnamt > 0"
                                              :rules="(item && (item.id === 28 || item.id === 29)) ? requiredRules : []"
                                              label="Descrição" clearable
                                              placeholder=" " maxlength="200">
                                    <template v-slot:append v-if="transacao.trnamt > 0">
                                        <v-btn :disabled="item === null || item === undefined" icon @click="openProjetosOuNotasFiscaisDialog">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                </v-text-field>

                            </v-col>
                        </v-row>
                        <v-row no-gutters style="background-color: whitesmoke;">
                            <v-col>
                                <v-subheader  class="text-uppercase caption">
                                    <span class="blue-grey--text">Pagamento</span>
                                </v-subheader>
                                <v-container class="pt-0">
                                    <v-row no-gutters>
                                        <v-col cols="6" class="pt-0">
                                            <v-select v-model="formaPagamento" class="pt-0 mt-0"
                                                      :items="formasPagamento" item-text="nome" return-object
                                                      label="Forma de Pagamento" single-line :rules="requiredRules" required
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row >
                                        <v-col cols="6">
                                            <v-text-field label="Valor"
                                                          :value="transacao.trnamt | formatNumber"
                                                          hide-details disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field label="Data Pagamento"
                                                          :value="transacao.dtposted | moment('DD/MM/YYYY')"
                                                          hide-details disabled></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>
                    <div class="float-right">
                        <v-btn color="primary" text depressed @click="save" >
                            Salvar
                        </v-btn>
                    </div>
                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
        <vincular-projetos-dialog ref="vincularProjetosDialog" @onServicosSelecionados="onServicosSelecionados" />
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import VincularProjetosDialog from "@/views/financeiro/conta/VincularProjetosOuRetornoDialog";

export default {
    name: "CriarMovimentacaoDialog",
    components: {VincularProjetosDialog},
    data() {
        return {
            dialog: false,
            transacao: null,
            loading: false,
            form1Valid: true,
            requiredRules: [
                (v) => !!v || 'Campo obrigatório',
            ],
            categorias: [],
            item: null,
            descricao: null,
            formaPagamento: null,
            formasPagamento: [],
            servicosIdsSelecionados: [],
            subItemType: 0,
            notasFiscaisIdsSelecionadas: []
        }
    },
    watch:{
        descricao(value){
            if(!value){
                this.servicosIdsSelecionados = []
                this.notasFiscaisIdsSelecionadas = []
            }
        }
    },
    computed: {
        itensFiltrados(){
            let itens = [];

            for(let categoria of this.categorias){
                itens.push({header: categoria.nome})
                for(let item of categoria.itens){
                    itens.push(item)
                }
                itens.push({ divider: true })
            }

            return itens;
        }
    },
    methods: {
        openDialog(transacao) {
            this.dialog = true;
            this.transacao = transacao;
            this.item = null;
            this.descricao = null;
            this.formaPagamento = null;
            this.servicosIdsSelecionados = []
            this.subItemType = 0;
            this.getRecursos();
        },
        closeDialog() {
            this.dialog = false;
        },
        getRecursos(){
            let itensReceitaQuery = gql`query {
                    categorias:categoriasReceita{
                        id
                        nome
                        itens{
                            id
                            nome
                            descricao
                        }
                    }
                    formasPagamento{
                        id
                        nome
                    }
                }`;
            let itensDespesaQuery = gql`query {
                    categorias:categoriasDespesa{
                        id
                        nome
                        itens{
                            id
                            nome
                            descricao
                        }
                    }
                    formasPagamento{
                        id
                        nome
                    }
                }`;

            this.loading = true;
            this.$apollo.query({
                query : this.transacao.trnamt > 0 ? itensReceitaQuery : itensDespesaQuery,
            }).then((result) => {
                this.categorias = result.data.categorias;
                this.formasPagamento = result.data.formasPagamento;

                if(this.transacao.memo.startsWith("LIQUIDACAO DE COBRANCA")){
                    this.item = this.categorias[0].itens.find(item => item.id === 28)
                    if(this.item){
                        //Esconder o campo de item
                    }
                    this.formaPagamento = this.formasPagamento.find(pag => pag.id === 3)
                }
            }).finally(()=>{
                this.loading = false
            });
        },
        openProjetosOuNotasFiscaisDialog(){
            if(this.item.id === 28){
                this.$refs.vincularProjetosDialog.openDialog(this.servicosIdsSelecionados, this.subItemType, this.transacao)
            }
        },
        onServicosSelecionados(servicosIds, subItemType, descricao){
            this.servicosIdsSelecionados = servicosIds;
            this.subItemType = subItemType;
            this.descricao = descricao;
            this.criaDescricao(this.item)
        },
        onItemChange(item){
            this.criaDescricao(item)
        },
        criaDescricao(item){
            if(item){
                /*if(item.id === 28){
                    if(this.projetosIdsSelecionados.length === 0){
                        this.descricao = null;
                    }else{
                        this.descricao = "Referente a " + this.projetosIdsSelecionados.length + " projetos"
                    }
                }*/

                if(item.id === 29){
                    if(this.notasFiscaisIdsSelecionadas.length === 0){
                        this.descricao = null;
                    }else{
                        this.descricao = "Referente a " + this.notasFiscaisIdsSelecionadas.length + " fiscalizações"
                    }
                }
            }
        },
        save() {
            if (!this.$refs.form1.validate()) {
                return false
            }
            this.loading = true;
            new Promise((resolve, reject) => {
                if(this.transacao.trnamt > 0){
                    this.saveReceita().then(()=>{
                        resolve();
                    }).catch(()=>{
                        reject()
                    })
                }else{
                    this.saveDespesa().then(()=>{
                        resolve();
                    }).catch(()=>{
                        reject()
                    })
                }
            }).then(() => {
                this.$emit("saved");
            }).finally(() => {
                this.loading = false
                this.closeDialog();
            })


        },
        saveReceita(){
            let servicosIds = [];

            if(this.item.id === 28){
                servicosIds = this.servicosIdsSelecionados
            }

            if(this.item.id === 29){
                servicosIds = this.notasFiscaisIdsSelecionadas
            }

            return this.$apollo.mutate({
                mutation: gql`mutation($itemId: Int!, $subItemType: Int, $servicosIds: [Int!]!, $formaPagamentoId: Int!, $transacaoId: Int!) {
                    movimentacao:addReceitaComPagamentoETransacaoEServicos(itemId: $itemId, subItemType: $subItemType, servicosIds: $servicosIds, formaPagamentoId: $formaPagamentoId, transacaoId: $transacaoId){
                        pagamento{
                            id
                            movimentacao{
                                id
                            }
                        }
                    }
                }`,
                variables: {
                    itemId: this.item.id,
                    servicosIds: servicosIds,
                    formaPagamentoId: this.formaPagamento.id,
                    transacaoId: this.transacao.id,
                    subItemType: this.subItemType
                },
            }).then((result) => {
                this.transacao.movimentacaoPagamento = result.data.movimentacao.pagamento;
            })
        },
        saveDespesa(){
            return this.$apollo.mutate({
                mutation: gql`mutation($itemId: Int!, $descricao: String, $formaPagamentoId: Int!, $transacaoId: Int!) {
                    movimentacao:addMovimentacaoComPagamentoETransacao(itemId: $itemId, descricao: $descricao, formaPagamentoId: $formaPagamentoId, transacaoId: $transacaoId){
                        pagamento{
                            id
                            movimentacao{
                                id
                            }
                        }
                    }
                }`,
                variables: {
                    itemId: this.item.id,
                    descricao: this.descricao,
                    formaPagamentoId: this.formaPagamento.id,
                    transacaoId: this.transacao.id
                },
            }).then((result) => {
                this.transacao.movimentacaoPagamento = result.data.movimentacao.pagamento;
            })
        }
    }
}
</script>

<style scoped>

</style>