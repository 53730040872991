<template>
    <v-dialog v-model="dialog" persistent scrollable max-width="1200px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Vincular com movimentação</span>
            </v-card-title>
            <v-card-text class="py-0">
                <div >
                    <v-row no-gutters>
                        <v-col>
                            <span class="body-1 black--text mr-2">Transação</span>
                            <div class="grey lighten-5 py-1 px-3 mb-4 mt-1">
                                <v-row>
                                    <v-col class="pb-0 pt-0 shrink">
                                        <span class="d-block caption font-weight-medium">Dcto.</span>
                                        <span>{{transacao.checknum}}</span>
                                    </v-col>
                                    <v-col class="pb-0 pt-0">
                                        <span class="d-block caption font-weight-medium">Lançamento</span>
                                        <span>{{transacao.memo}}</span>
                                    </v-col>
                                    <v-col class="pb-0 pt-0 shrink" style="white-space: nowrap">
                                        <span class="d-block caption font-weight-medium">Valor</span>
                                        <span :class="transacao.trnamt > 0 ? 'indigo--text' : 'red--text'">{{transacao.trnamt | formatNumber}}</span>
                                    </v-col>
                                    <v-col class="pb-0 pt-0 shrink" style="white-space: nowrap">
                                        <span class="d-block caption font-weight-medium">Data</span>
                                        <span>{{transacao.dtposted | moment("DD/MM/YYYY HH:mm")}}</span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="shrink">
                            <v-btn color="primary"  @click="criarMovimentacao">Criar Movimentação</v-btn>
                        </v-col>
                        <v-col class="shrink px-4 d-flex align-center">
                            <span>ou</span>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="searchValue" background-color="white" autofocus
                                          placeholder="Buscar por descrição, categoria, item, valor ou data de pagamento" hide-details
                                          outlined dense clearable></v-text-field>
<!--                            <v-text-field background-color="white" autofocus
                                          placeholder="Buscar por descrição, categoria, item, valor ou data de pagamento" hide-details
                                          outlined dense clearable
                                          @input="onInputValueChanged"></v-text-field>-->
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-simple-table class="tabela-movimentacoes" fixed-header height="250px" >
                                <template v-slot:default>
                                    <thead>
                                    <tr style="white-space: nowrap">
<!--                                        <th class="text-left">
                                            Criado em
                                        </th>-->
                                        <th class="text-left">
                                            Categoria
                                        </th>
                                        <th class="text-left">
                                            Item
                                        </th>
                                        <th class="text-left" >
                                            Descrição
                                        </th>
                                        <th class="text-left">
                                            Valor
                                        </th>
                                        <th class="text-left">
                                            Pagamento
                                        </th>
<!--                                        <th class="text-left">
                                            Caixa
                                        </th>-->
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(movimento, index) in movimentosOrdenados" :key="index" style="white-space: nowrap">
<!--                                        <td class="grey&#45;&#45;text darken-3 caption">
                                            {{ movimento.createdAt | moment("DD/MM/YYYY HH:mm") }}
                                        </td>-->
                                        <td>
                                            <v-chip color="teal lighten-4" x-small  label
                                                    class="text-uppercase font-weight-medium">
                                                {{movimento.item.categoria.nome}}
                                            </v-chip>
                                        </td>
                                        <td>
                                            {{movimento.item.nome}}
                                        </td>
                                        <td>
                                            <template v-if="movimento.item.id === 29">
                                                <a href="" @click.prevent="showNotasFiscais(movimento.id)">
                                                    {{movimento.descricao}}
                                                </a>
                                            </template>
                                            <template v-else>
                                                {{movimento.descricao}}
                                            </template>
                                        </td>
                                        <td>
                                            <span :class="movimento.valor > 0 ? 'indigo--text' : 'red--text'">
                                                {{ movimento.valor | formatNumber }}
                                            </span>
                                        </td>
                                        <td>
                                            {{ movimento.pagamento.dataPagamento | moment("DD/MM/YYYY") }} - {{movimento.pagamento.formaPagamento.nome}}
                                            <v-icon v-if="!movimento.temDivergenciaComTransacoes"
                                                    small class="green--text float-right" title="Verificado">mdi-check-circle</v-icon>
                                            <v-icon v-else
                                                    small class="orange--text float-right" title="Divergência">mdi-alert</v-icon>
                                        </td>
<!--                                        <td>
                                            <span v-if="movimento.fechamento">{{movimento.fechamento.nome}}</span>
                                            <span v-else class="orange&#45;&#45;text">Caixa Aberto</span>
                                        </td>-->
                                        <td>
                                            <v-btn x-small color="primary" :disabled="movimento.fechamento"
                                                   @click="onSelecionarButtonClicked(movimento)">Selecionar</v-btn>
                                        </td>
                                    </tr>
                                    <tr v-if="movimentacoes.length === 0">
                                        <td colspan="7" class="text-center">Nenhum resultado encontrado</td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>
                </div>
            </v-card-actions>
        </v-card>
        <movimentacao-notas-fiscais-dialog ref="movimentacaoNotasFiscaisDialog" />
        <criar-movimentacao-dialog ref="criarMovimentacaoDialog" @saved="onMovimentacaoSaved" />
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import MovimentacaoNotasFiscaisDialog from "@/views/financeiro/MovimentacaoNotasFiscaisDialog";
import CriarMovimentacaoDialog from "@/views/financeiro/conta/CriarMovimentacaoDialog";

export default {
    name: "VincularMovimentacaoDialog",
    components: {
        CriarMovimentacaoDialog,
        MovimentacaoNotasFiscaisDialog,
    },
    data(){
        return{
            dialog: false,
            loading: false,
            form1Valid: true,
            movimentacaoId: null,
            movimentacoes: [],
            offset: 200,
            page: 1,
            totalCount: 0,
            transacao: null,
            searchValue: null
        }
    },
    watch: {
        searchValue(value) {
            this.getList({searchValue: value})
        },
    },
    computed:{
        movimentosOrdenados(){
            return this.movimentacoes.sort((a, b) => {
                if(a.temDivergenciaComTransacoes){
                    return -1
                }

                if(!a.pagamento){
                    return -1
                }else if (!b.pagamento){
                    return 1
                }
                var data1 = this.$moment(a.pagamento.dataPagamento);
                var data2 = this.$moment(b.pagamento.dataPagamento);

                if (data1.isBefore(data2)) return -1;
                if (data1.isAfter(data2)) return 1;

                var createdAt1 = this.$moment(a.createdAt);
                var createdAt2 = this.$moment(b.createdAt);

                if (createdAt1.isBefore(createdAt2)) return -1;
                if (createdAt1.isAfter(createdAt2)) return 1;
                return 0;
            })
        }
    },
    methods: {
        openDialog(transacao) {
            this.dialog = true;
            this.transacao = transacao;
            this.movimentacoes = []
            this.getList({searchValue: ''})
        },
        closeDialog() {
            this.dialog = false;
        },
        onInputValueChanged(value) {
            //this.$emit('input', this.getFilterObject());
            if(value.length > 3){
                this.getList({searchValue: value})
            }else{
                this.movimentacoes = []
            }
        },
        getList(filter) {
            this.loading = true;

            filter.itens = [{
                data: {falseLabel: "Não", status: false, trueLabel: "Sim"},
                menu: false,
                name: 'fechadas',
                title: 'Mostrar Fechados',
                type: 'boolean',
            }]

            this.$apollo.query({
                query: gql`query($filter: SearchFilterInput!, $page: Int, $offset: Int) {
                        searchMovimentacoes(filter: $filter, page: $page, offset: $offset){
                            totalCount
                            movimentacoes:itens{
                                id
                                descricao
                                valor
                                createdAt
                                temDivergenciaComTransacoes
                                item{
                                    id
                                    nome
                                    categoria{
                                        nome
                                    }
                                }
                                pagamento{
                                    id
                                    dataPagamento
                                    formaPagamento{
                                        nome
                                    }
                                }
                            }
                        }
                    }`,
                variables: {
                    filter: this.formatFilterToRequest(filter),
                    page: this.page - 1,
                    offset: this.offset
                },
            }).then((result) => {
                this.movimentacoes = result.data.searchMovimentacoes.movimentacoes;
                //this.totalCount = result.data.searchMovimentacoes.totalCount;
                this.loading = false;
            });
        },
        formatFilterToRequest(filter) {
            const parsedFilter = this._.cloneDeep(filter);

            if (!parsedFilter.searchValue) {
                parsedFilter.searchCriteria = null;
            }

            if (filter.itens) {
                parsedFilter.itens = filter.itens.map((filterItem) => {
                    const filterItemMapped = {
                        name: filterItem.name,
                    };

                    switch (filterItem.type) {
                        case 'boolean':
                            filterItemMapped.booleanData = this._.cloneDeep(filterItem.data);
                            delete filterItemMapped.booleanData.trueLabel;
                            delete filterItemMapped.booleanData.falseLabel;
                            delete filterItemMapped.booleanData.toLabel;

                            break;
                        case 'sort':
                            filterItemMapped.sortData = this._.cloneDeep(filterItem.data);
                            filterItemMapped.sortData.fieldName = filterItemMapped.sortData.field.name;
                            delete filterItemMapped.sortData.field;
                            delete filterItemMapped.sortData.toLabel;
                            break;
                        case 'date':
                            filterItemMapped.dateData = this._.cloneDeep(filterItem.data);
                            delete filterItemMapped.dateData.rangeType;
                            delete filterItemMapped.dateData.toLabel;
                            break;
                    }

                    return filterItemMapped;
                });
            }

            return parsedFilter;
        },
        onSelecionarButtonClicked(movimentacao){
            if(movimentacao.valor < this.transacao.trnamt){
                this.$dialog.warning({
                    title: 'Atenção',
                    text: 'O valor da transação é maior que o valor do movimento. Deseja continuar mesmo assim?',
                    actions:{
                        false: 'Cancelar',
                        yes: {
                            text: 'Sim',
                            color: 'primary',
                        },
                    }})
                    .then((result) => {
                        if (result) {
                            this.save(movimentacao)
                        }
                    });
                return
            }
            this.save(movimentacao);
        },
        save(movimentacao){
            this.$apollo.mutate({
                mutation: gql`mutation($movimentacaoId: Int!, $transacaoId: Int!) {
                    adicionarTransicaoNaMovimentacao(movimentacaoId: $movimentacaoId, transacaoId: $transacaoId){
                        id
                        movimentacaoPagamento{
                            id
                            movimentacao{
                                id
                            }
                            ofxTransactions{
                                id
                            }
                        }
                    }
                }`,
                variables: {
                    movimentacaoId: movimentacao.id,
                    transacaoId: this.transacao.id
                },
            }).then((result) => {
                this.transacao.movimentacaoPagamento = result.data.adicionarTransicaoNaMovimentacao.movimentacaoPagamento;
                this.$emit('saved');
                this.closeDialog()
            })
        },
        showNotasFiscais(id){
            this.$refs.movimentacaoNotasFiscaisDialog.openDialog(id)
        },
        criarMovimentacao(){
            this.$refs.criarMovimentacaoDialog.openDialog(this.transacao)
        },
        onMovimentacaoSaved(movimentacao){
            this.$emit('saved');
            this.closeDialog();
        },
    }
}
</script>

<style>

</style>
<style scoped>
    .tabela-movimentacoes table tbody tr td{
        font-size: 0.775rem;
    }
</style>